export default async function format(value, _options) {
  let formattedValue = value;

  if (Array.isArray(value) && value.length >= 2) {
    const [lon, lat] = value;
    formattedValue = `${lat.toFixed(6)}, ${lon.toFixed(6)}`;
  } else if (typeof value.lat === 'number' && typeof value.lon === 'number') {
    formattedValue = `${value.lat.toFixed(6)}, ${value.lon.toFixed(6)}`;
  } else if (typeof value.latitude === 'number' && typeof value.longitude === 'number') {
    formattedValue = `${value.latitude.toFixed(6)}, ${value.longitude.toFixed(6)}`;
  } else {
    formattedValue = '';
  }

  return formattedValue;
}
