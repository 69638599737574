import Backend from '../../../backend';
import get from '../get';

export default async function format(value, options) {
  let formattedValue = value;

  if (value) {
    let query = JSON.stringify(options.query);

    query = query.replace(/%value%/g, value);

    query = JSON.parse(query);

    const data = await Backend().search({
      plugin: options.plugin,
      object: options.object,
    }, query);

    if (data.result.hits.length > 0) {
      formattedValue = get(data.result, options.path, '');
    }
  } else {
    formattedValue = '';
  }

  return formattedValue;
}
