import get from './get';
import * as formats from './formats';
import _ from '../lodash';

/**
 * Format the data
 *
 * @param {*} rawValue the raw value
 * @param {object|undefined} options options of format
 *
 * @return {Promise<*>} formated data
 */
export default async function format(rawValue, options) {
  if (rawValue === null && typeof options.defaultValue !== 'undefined') {
    return options.defaultValue;
  }
  if (!options || typeof rawValue === 'undefined' || rawValue === null) {
    return rawValue;
  }

  let value = rawValue;

  if (options.condition) {
    /**
     * If condition is string prevent legacy format
     * TODO Remove legacy support
     */
    if (typeof options.condition === 'string') {
      options.condition = {
        type: 'regExp',
        path: options.conditionPath,
        testValue: options.condition,
      };
      console.warn(`Deprecated condition, please use condition object ${JSON.stringify(options.condition)}`);
    }
    const conditionValue = options.condition.testValue;

    // If path is defined get value from path otherwise use current value
    const testValue = options.condition.path ? get(value, options.condition.path) : value;

    // If conditions value is an array check if in valid values
    if (Array.isArray(conditionValue)) {
      for (let i = 0; i < conditionValue.length; i += 1) {
        if (!_.validate(options.condition.type, conditionValue[i], testValue)) {
          return null;
        }
      }
    } else if (!_.validate(options.condition.type, conditionValue, testValue)) {
      return null;
    }
  }

  // If value path is set get value from path
  if (options.valuePath) {
    if (typeof options.valuePath === 'string') {
      value = get(value, { path: options.valuePath, id: options.id });
    } else {
      value = get(value, { ...options.valuePath, id: options.id });
    }
  }

  let formattedValue;

  if (typeof formats.default[options.type] !== 'undefined') {
    formattedValue = await formats.default[options.type](value, options);
  } else {
    switch (options.type) {
      case 'concat':
        // Concat is handled here to avoid cyclic dependency issues
        if (typeof value === 'object') {
          const data = [];
          for (let i = 0; i < options.fields.length; i += 1) {
            const field = options.fields[i];
            let path = field;
            let entryFormat = false;
            if (typeof field === 'object'
              && typeof field.path !== 'undefined'
              && typeof field.format !== 'undefined') {
              path = field.path;
              entryFormat = field.format;
            }
            const entry = get(value, path, undefined);
            if (typeof entry !== 'undefined') {
              if (entryFormat) {
                // eslint-disable-next-line no-await-in-loop
                const entryFormated = await format(entry, { entryFormat });
                data.push(entryFormated);
              } else {
                data.push(entry);
              }
            }
          }
          const separator = typeof options.separator !== 'undefined'
            ? options.separator
            : ', ';
          formattedValue = data.filter((val) => !_.isEmpty(val)).join(separator);
          if (data.length === 0 && options.defaultField) {
            formattedValue = get(value, options.defaultField);
          }
        } else {
          formattedValue = '';
        }
        break;
      default:
        formattedValue = value;
        if (typeof value === 'number') {
          formattedValue = Math.round(value * 100) / 100;
        }
    }
  }

  // If formattedValue is empty replace by defaultValue if is defined
  if (_.isEmpty(formattedValue) && typeof options.defaultValue !== 'undefined') {
    if (options.localizedDefaultValue) {
      formattedValue = formats.default.localized(options.defaultValue, {});
    } else {
      formattedValue = options.defaultValue;
    }
  }

  if (options.length) {
    formattedValue = `${formattedValue}`.substr(0, options.length);
  }

  return formattedValue;
}
