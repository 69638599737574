import populated from './populated';
import _ from '../../lodash';
import get from '../get';

/**
 *? Actually this formater is specific to Decoset
 *? Maybe need refactor in future to be more versatile
 */
/**
 *
 * @param {{ location?: string } | string | null} value
 * @param {{ locationType?: string, path?: string}} options
 */
export default async function format(value, options = {}) {
  let formattedValue = value;

  // If value is an object
  if (typeof formattedValue === 'object') {
    formattedValue = get(formattedValue, 'location', undefined);
  }

  if (!_.isEmpty(formattedValue)) {
    let location = await populated(formattedValue, {
      plugin: 'environment-manager',
      object: 'locations',
      path: '',
    });
    const locationType = get(location._source, { poly: 'metadata', name: 'type' }, 'dumping');

    // Location type "site" is generic for clearout and dumping
    if (options.locationType === 'site') {
      // Get "parent" site id
      if (locationType === 'platform') {
        const siteId = get(location._source, { poly: 'metadata', name: 'site' });
        if (!_.isEmpty(siteId)) {
          // Get parent location
          location = await populated(siteId, {
            plugin: 'environment-manager',
            object: 'locations',
            path: '',
          });
        }
      }
    } else if (options.locationType && options.locationType !== locationType) {
      // If need specific location type but not corresponding
      return '';
    }
    return get(location, options.path || '');
  }

  return formattedValue;
}
