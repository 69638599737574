import get from './get';
import set from './set';
import format from './format';
import getAliasValue from './getAliasValue';

export default {
  get,
  set,
  format,
  getAliasValue,
};
