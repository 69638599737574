import { set as dotSet } from 'dot-wild';

/**
 * Add value on path
 *
 * @param {Array|Object} object Object data
 * @param {String|Object|Number} path Path to value
 * @param {*} value Value to set at path
 * @param {boolean} [_overwrite] Define if the value is added or replace previous
 *
 * @return {Array|Object} New Array|Object modified
 */
export default function set(object, path, value, _overwrite = false) {
  if (typeof path !== 'string') {
    console.error(`[dataAcces.set] Invalid path ${path}`);
  }
  if (typeof value === 'undefined' || typeof path !== 'string') {
    return object;
  }

  return dotSet(object, path, value);
}
