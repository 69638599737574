import { get as dotGet } from 'dot-wild';

/**
 * Add value on path
 *
 * @param {Array|Object} object Object data
 * @param {String|Object|Number} path Path to value
 * @param {*} [defaultValue] Default value if data not found
 *
 * @return {*} Data
 */
export default function get(object, path, defaultValue) {
  if (typeof path === 'undefined' || path === '') {
    return object;
  }

  if (typeof path !== 'string') {
    if (Array.isArray(path.fields)) {
      const data = [];
      for (let i = 0; i < path.fields.length; i += 1) {
        const value = get(object, path.fields[i]);
        if (typeof value !== 'undefined' && value !== null) {
          if (path.multiple !== true) {
            return value;
          }
          data.push(value);
        }
      }
      return (data.length > 1) ? data : defaultValue;
    }

    // return defaultValue for invalid path
    let value = defaultValue;

    if (typeof path.path !== 'undefined') {
      // simple object path
      value = dotGet(object, path.path, defaultValue);
    } else {
      console.error(`${JSON.stringify(path)} is not an valid path`);
      console.log('tryed to request from => ', object);
    }

    if (Array.isArray(value) && typeof path.id !== 'undefined') {
      return value[path.id];
    }

    return (typeof value === 'undefined') ? defaultValue : value;
  }

  return dotGet(object, path, defaultValue);
}
