export default async function format(value, _options) {
  let formattedValue = value;

  let coordinates;
  if (Array.isArray(value) && value.length >= 2) {
    const [lon, lat] = value;
    coordinates = `${lat.toFixed(6)},${lon.toFixed(6)}`;
  } else if (typeof value.lat === 'number' && typeof value.lon === 'number') {
    coordinates = `${value.lat.toFixed(6)},${value.lon.toFixed(6)}`;
  }

  if (coordinates) {
    formattedValue = `https://www.google.com/maps?q&layer=c&cbll=${coordinates}&cbp=11,0,0,0,0`;
  } else {
    formattedValue = '';
  }

  return formattedValue;
}
