import getAliasValue from '../getAliasValue';

export default async function format(value, options) {
  let formattedValue = value;

  const aliasOptions = {
    alias: options.aliasIcons || options.alias,
    defaultValue: options.defaultIcon,
  };
  formattedValue = getAliasValue(aliasOptions, value);

  return formattedValue;
}
