const formatModules = require.context('.', true, /[A-Za-z0-9-_,\s]+\.js$/i);
const formats = {};
formatModules.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1 && matched[1] !== 'index') {
    const formatName = matched[1];
    formats[formatName] = formatModules(key).default;
  }
});

export default formats;
