import _ from '../lodash';

export default function getAliasValue(options, value) {
  let alias = null;
  const defaultValue = options.defaultValue || false;
  if (!_.isEmpty(value) && options.alias) {
    alias = options.alias[value] || null;
  }
  if (alias !== null) {
    return alias;
  }
  if (defaultValue !== false) {
    return defaultValue;
  }
  return value;
}
