import getI18n from '../../../plugins/i18n';

function formatValue(value, options) {
  // Get current I18n instance (started in main.js)
  const i18n = getI18n(localStorage.locale);
  let formattedValue = value;

  if (options.prefix) {
    const translationPath = `${options.prefix}.${value}`;
    if (translationPath === `${options.prefix}.`) {
      formattedValue = '';
    } else {
      formattedValue = i18n.t(translationPath);
    }
  } else {
    formattedValue = i18n.t(value);
  }

  return formattedValue;
}

export default async function format(value, options) {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      const i18n = getI18n(localStorage.locale);
      return i18n.t(`${options.prefix}.undefined`);
    }
    return formatValue(value[0], options);
  }

  return formatValue(value, options);
}
