import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import formatDistance from 'date-fns/formatDistance';

const dateLocales = {
  en,
  fr,
};

export default async function format(value, _options) {
  let formattedValue = value;

  const [base, date] = (Array.isArray(value) ? value : [0, value]).map((n) => Number(n));
  formattedValue = formatDistance(base, date, {
    locale: dateLocales[localStorage.locale],
  });

  return formattedValue;
}
