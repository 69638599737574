import Backend from '../../../backend';
import get from '../get';

export default async function format(value, options) {
  let formattedValue = value;

  if (value) {
    const data = await Backend().read({
      plugin: options.plugin,
      object: options.object,
    }, value);
    if (typeof data.result !== 'undefined') {
      formattedValue = get(data.result, options.path, '');
    }
  } else {
    formattedValue = '';
  }

  return formattedValue;
}
